import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzRequestAccessModalComponent } from './kurz-request-access-modal.component';
import { I18nModule } from '@spartacus/core';
import { ButtonModule } from '@util/components/button/button.module';
import { InputModule } from '@util/components/input/input.module';
import { BaseFormValidatorsModule } from '@util/components/shared/base-form/base-form-validators/base-form-validators.module';
import { ColorThemeModule } from '@util/components/shared/color-theme/color-theme.module';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';
import { KurzModalWrapperModule } from '../kurz-modal-wrapper/kurz-modal-wrapper.module';
import { KurzCountryAutocompleteModule } from '../kurz-country-autocomplete/kurz-country-autocomplete.module';
import { HtmlDeserializerModule } from '@util/components/html-deserializer/html-deserializer.module';



@NgModule({
  declarations: [
    KurzRequestAccessModalComponent
  ],
  imports: [
    CommonModule,
    KurzModalWrapperModule,
    //
    InputModule,
    KurzCountryAutocompleteModule,
    BaseFormValidatorsModule,
    ButtonModule,
    ColorThemeModule,
    ActivateElementModule,
    I18nModule,
    HtmlDeserializerModule
  ]
})
export class KurzRequestAccessModalModule { }
