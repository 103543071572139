import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule, UrlModule } from '@spartacus/core';
import { CustomLoginFormComponent } from './custom-login-form.component';
import { IconButtonModule } from '@util/components/icon-button/icon-button.module';
import { ButtonModule } from '@util/components/button/button.module';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';
import { InputModule } from '@util/components/input/input.module';
import { LinkButtonModule } from '@util/components/link-button/link-button.module';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';
import { RouterModule } from '@angular/router';
import { IconModule } from '@util/components/icon/icon.module';
import { KurzRequestAccessModalModule } from '../../kurz-components/shared/kurz-request-access-modal/kurz-request-access-modal.module';
import { KurzCountrySelectorModule } from '../../kurz-components/shared/kurz-country-selector/kurz-country-selector.module';


@NgModule({
  declarations: [
    CustomLoginFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    IconButtonModule,
    ButtonModule,
    FormErrorsModule,
    InputModule,
    IconModule,
    LinkButtonModule,
    SpinnerModule,
    ActivateElementModule,
    UrlModule,
    RouterModule,
    KurzRequestAccessModalModule,
    KurzCountrySelectorModule
  ],
  exports: [
    CustomLoginFormComponent
  ]
})
export class CustomLoginFormModule { }
